import { ENTITY_METADATA } from 'app/services/i-http-service/constants';
import { debug } from 'debug';

import { HttpService } from '../../app/services/http.service';
import { IHttpService } from '../../app/services/i-http-service/ihttp.service';
import { CriteriaResult } from '../../app/utils/useFilterCriteria';
import { Type } from '../common';
import { Criteria } from '../common/criteria';

export type EntityId = number | string;

export class EntityService<TEntity = any> {
  private _skipClassConversion = false;

  public readonly defaultResponse: CriteriaResult<any> = {
    data: [],
    pagination: {
      total: 1,
      totalItems: 0,
      perPage: 0,
      current: 1,
    },
  };

  constructor(public readonly target: Type<TEntity>) {}

  private _baseUrl: string;

  private _defaultParams: Record<string, any>;

  private log = debug(EntityService.name);

  skipClassConversion(state: boolean): this {
    this._skipClassConversion = state;

    return this;
  }

  get baseUrl(): string {
    if (!this._baseUrl) {
      this._baseUrl = Reflect.getMetadata(ENTITY_METADATA, this.target);
    }

    return this.parseParams(this._baseUrl, this._defaultParams);
  }

  setBaseParams(params: Record<string, any>): this {
    this._defaultParams = params;

    return this;
  }

  private get instance(): IHttpService<any> {
    return HttpService.$instance.as(this.target);
  }

  async get<Response = CriteriaResult<TEntity>>(
    criteria?: Criteria,
    params?: Record<string, any>,
    queryParams?: Record<string, any>
  ): Promise<Response> {
    this.log('@get', { target: this.target, criteria });

    const url = this.parseParams(this.baseUrl, params);

    this.log('url construido:', url);

    // TODO: Trocar para "isBaseUrlValid"
    // ! Se você chegou até aqui, provavelmente você está tentando fazer uma requisição
    // ! com parâmetros que não foram definidos, ou demorou até ser definida.
    // * É comum logar pelo menos uma vez, dependendo do modo de definição escolhido.
    if (
      this.hasParams(this.baseUrl) &&
      Object.keys(params || {}).length === 0 &&
      criteria
    ) {
      console.warn(
        'Uma URL com parâmetros foi passada mas estes não foram definidos. Retornando valores padrões.'
      );

      return this.defaultResponse as unknown as Response;
    }

    return await this.instance.get(this.buildUrl(url, queryParams), criteria);
  }

  async getOne<R = TEntity>(
    id?: EntityId,
    additionalUrl?: string,
    params?: Record<string, any>
  ): Promise<R> {
    let url = `${this.baseUrl}/${id}`;

    if (additionalUrl) {
      url = url.concat(this.parseParams(additionalUrl, params));
    }

    if (!id) {
      return this.instance.get(this.baseUrl);
    }

    return this.instance.get(url, {
      skipClassConversion: this._skipClassConversion,
    });
  }

  async getCustom<R = CriteriaResult<TEntity>, C = R>(
    additionalUrl: string,
    params?: Record<string, any>,
    criteria?: Criteria<C>,
    queryParams?: Record<string, any>
  ): Promise<R> {
    additionalUrl = this.parseParams(additionalUrl, params);

    const url = `${this.baseUrl}/${additionalUrl}`;

    return this.instance.get(this.buildUrl(url, queryParams), criteria);
  }

  async put<R = TEntity, K = Partial<TEntity>>(data?: K): Promise<R> {
    return this.instance.put(this.baseUrl, data);
  }

  async putCustom<R = TEntity, K = Partial<TEntity>>(
    additionalUrl: string,
    data?: K
  ): Promise<R> {
    return this.instance.put(`${this.baseUrl}/${additionalUrl}`, data);
  }

  async patch<R = TEntity, K = Partial<TEntity>>(data: K): Promise<R> {
    return this.instance.patch(this.baseUrl, data);
  }

  async patchCustom<R = TEntity, K = Partial<TEntity>>(
    additionalUrl: string,
    data: K,
    params?: Record<string, any>
  ): Promise<R> {
    const baseUrl = this.parseParams(
      `${this.baseUrl}/${additionalUrl}`,
      params
    );

    return this.instance.patch(baseUrl, data);
  }

  async updateOne<R = TEntity, K = Partial<TEntity>>(
    id: EntityId,
    data: K
  ): Promise<R> {
    return this.instance.put(`${this.baseUrl}/${id}`, data);
  }

  async post<R = TEntity, K = Partial<TEntity>>(data: K): Promise<R> {
    return this.instance.post(this.baseUrl, data);
  }

  async postCustom<R = TEntity, K = Partial<TEntity>>(
    additionalUrl: string,
    data: K,
    params?: Record<string, any>
  ): Promise<R> {
    const url = this.parseParams(`${this.baseUrl}/${additionalUrl}`, params);

    return this.instance.post(url, data);
  }

  async delete<R = TEntity, Data = Partial<TEntity>>(
    id: EntityId,
    data?: Data
  ): Promise<R> {
    return this.instance.delete(`${this.baseUrl}/${id}`, data);
  }

  async deleteCustom<R = TEntity, Data = Partial<TEntity>>(
    additionalUrl: string,
    data?: Data,
    params?: Record<string, any>
  ): Promise<R> {
    additionalUrl = this.parseParams(additionalUrl, params);

    return this.instance.delete(`${this.baseUrl}/${additionalUrl}`, data);
  }

  private buildUrl(url: string, queryParams?: Record<string, any>): string {
    if (queryParams) {
      const params = this.parseQueryParams(queryParams);

      if (url.includes('?')) {
        url += `&${params}`;
      } else {
        url += `?${params}`;
      }
    }

    return url;
  }

  private parseQueryParams(queryParams?: Record<string, any>): string {
    let params = '';
    if (queryParams) {
      params = Object.keys(queryParams)
        .map((key) => `${key}=${queryParams[key]}`)
        .join('&');
    }

    return params;
  }

  private hasParams(url: string): boolean {
    return url.includes(':');
  }

  private parseParams(source?: string, params?: Record<string, any>): string {
    if (source) {
      if (params) {
        const keys = Object.keys(params);

        keys.forEach((key) => {
          source = source.replace(`:${key}`, params[key]);
        });
      }

      return source;
    }

    return '';
  }

  getRequiredParams(): string[] {
    if (!this._baseUrl) {
      return [];
    }

    const requiredParams: string[] = [];

    this._baseUrl.split('/').forEach((element) => {
      if (element.includes(':')) {
        const [, param] = element.split(':');
        requiredParams.push(param);
      }
    });

    return requiredParams;
  }

  isBaseUrlValid(): boolean {
    const requiredParams = this.getRequiredParams();
    const params = Object.keys(this._defaultParams);

    return requiredParams.every(
      (key) => params.includes(key) && !!this._defaultParams[key]
    );
  }
}
