import { Injectable } from '@angular/core';
import { createClass } from '@imagine/common';
import { EmitterAction, Receiver } from '@ngxs-labs/emitter';
import { Selector, State, StateContext } from '@ngxs/store';
import { BluetoothDevice } from 'app/bluetooth/interfaces/bluetooth-device.interface';

export interface BluetoothStateModel {
  connectedDevice: BluetoothDevice | null;
}

@State<BluetoothStateModel>({
  name: 'bluetoothState',
  defaults: {
    connectedDevice: null,
  },
})
@Injectable()
export class BluetoothState {
  @Selector()
  public static getConnectedDevice(
    state: BluetoothStateModel
  ): BluetoothDevice | null {
    return createClass(BluetoothDevice, state.connectedDevice, {
      enableCircularCheck: false,
    });
  }

  @Receiver()
  public static setConnectedDevice(
    { patchState }: StateContext<BluetoothStateModel>,
    { payload }: EmitterAction<BluetoothDevice>
  ): void {
    patchState({ connectedDevice: payload });
  }
}
