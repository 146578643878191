import { SEMICOLON } from '../constants';

import { CriteriaFilter } from './criteria-filter';

export const parseCriteriaFilter = (criteriaFilter: CriteriaFilter): string => {
  const filters: string[] = [];

  const searchFields = criteriaFilter.data;

  for (const { field, value, operator } of searchFields) {
    if (value) {
      let filter = `${field}:${value}`;

      if (operator) {
        filter += `|${operator}`;
      }

      filters.push(filter);
    }
  }

  return filters.join(SEMICOLON);
};
