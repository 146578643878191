import { Name } from 'app/decorators/name.decorator';
import { Sync } from 'app/decorators/sync.decorator';
import { Endpoint } from '../services/i-http-service';
import { LawParagraph } from './law-paragraph.entity';

@Sync('get')
@Endpoint('laws')
@Name('Leis')
export class Laws {
  id: number;
  name: string;
  description: string;
  paragraphs?: LawParagraph[];
}
