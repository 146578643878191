import { HttpClient, HttpResponse } from '@angular/common/http';

import { createClass } from '../../../@imagine/common';
import { Criteria } from '../../../@imagine/common/criteria/criteria';
import { CriteriaResult } from '../../utils/useFilterCriteria';
import { HttpService } from '../http.service';

import { HttpCriteriaOptions, HttpOptions } from './ihttp.service.interface';

export class IHttpService<Entity> {
  private readonly options: HttpOptions = {
    responseType: 'json',
    observe: 'events',
  };

  constructor(
    private readonly httpService: HttpService,
    private readonly http: HttpClient,
    private readonly entity: new () => Entity
  ) {}

  async get<Result = CriteriaResult<Entity>>(
    endpoint: string,
    // eslint-disable-next-line @typescript-eslint/unified-signatures
    options: HttpCriteriaOptions<Entity>
  ): Promise<Result>;
  // eslint-disable-next-line @typescript-eslint/unified-signatures
  async get<Result = CriteriaResult<Entity>>(
    endpoint: string,
    criteria: Criteria
  ): Promise<Result>;
  async get<Result = Entity>(endpoint: string): Promise<Result>;
  async get<Result = Entity>(
    endpoint: string,
    options?: Partial<HttpCriteriaOptions<Entity>> | Criteria
  ): Promise<Entity | CriteriaResult<Entity> | Result[]> {
    // ? Se for pular a conversão de classe, iniciamos como falso
    let skipClassConversion: boolean;

    if (options instanceof Criteria) {
      this.options.params = options.toObject() as Record<string, any>;

      skipClassConversion = options.skipClassConversion;
    } else if (options?.params) {
      this.options.params = options.params;

      skipClassConversion = !!options?.skipClassConversion;
    }

    const httpResponse = (await this.http
      .get<unknown | unknown[]>(
        `${this.httpService.baseApi}${endpoint}`,
        this.options
      )
      .toPromise()) as HttpResponse<Entity>;

    const plainEntity = httpResponse.body;

    if (skipClassConversion) {
      return plainEntity;
    }

    return createClass(this.entity, plainEntity);
  }

  async post<K = Record<string, any>>(
    endpoint: string,
    context?: K
  ): Promise<Entity> {
    const httpResponse = (await this.http
      .post<unknown>(
        `${this.httpService.baseApi}${endpoint}`,
        context,
        this.options
      )
      .toPromise()) as HttpResponse<Entity>;

    const plainEntity = httpResponse.body;

    return createClass(this.entity, plainEntity);
  }

  async put<K = Record<string, any>>(
    endpoint: string,
    context?: K
  ): Promise<Entity> {
    const httpResponse = (await this.http
      .put<unknown>(
        `${this.httpService.baseApi}${endpoint}`,
        context,
        this.options
      )
      .toPromise()) as HttpResponse<Entity>;

    const plainEntity = httpResponse.body;

    return createClass(this.entity, plainEntity);
  }

  async patch<K = Record<string, any>>(
    endpoint: string,
    data?: K
  ): Promise<Entity> {
    const httpResponse = (await this.http
      .patch<unknown>(
        `${this.httpService.baseApi}${endpoint}`,
        data,
        this.options
      )
      .toPromise()) as HttpResponse<Entity>;

    const plainEntity = httpResponse.body;

    return createClass(this.entity, plainEntity);
  }

  async delete<K = Record<string, any>>(
    endpoint: string,
    data?: any
  ): Promise<Entity> {
    const httpResponse = (await this.http
      .delete<unknown>(`${this.httpService.baseApi}${endpoint}`, {
        ...this.options,
        body: data,
      })
      .toPromise()) as HttpResponse<Entity>;

    const plainEntity = httpResponse.body;

    return createClass(this.entity, plainEntity);
  }
}
