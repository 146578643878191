import { isDevMode } from '@angular/core';

export class AuthError extends Error {
  constructor(message: string, original?: Error) {
    super(message);

    if (isDevMode() && original) {
      console.warn('original AuthError', original);
    }
  }
}
