import { Endpoint } from 'app/services/i-http-service';

@Endpoint('applications/attachment/:linkedId')
export class ApplicationFile {
  filename: string;
  content: string;
  size: number;
  integrity?: string;
  mimeType: string;
  simplifiedType: string;
  fileSyncKey: string;
}
