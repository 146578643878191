import debug from 'debug';
import memoizee from 'memoizee';

const log = debug('Memoize');

export type MemoizeErrorStrategy = 'clear-all' | 'clear-current' | 'none';

export interface MemoizeOptions {
  maxAge?: number;
  async?: boolean;
  onErrorStrategy?: MemoizeErrorStrategy;
  length?: number | false;
  max?: number;
  // ? DEFAULT = true
  primitive?: boolean;
}

const DEFAULT_MEMOIZEE_OPTIONS: MemoizeOptions = Object.freeze({
  primitive: false,
  max: 10
});

export const Memoize = (options: MemoizeOptions = {}): MethodDecorator => (target, propertyKey: string, descriptor: PropertyDescriptor): void => {
  const original = descriptor.value;

  const newFunction: memoizee.Memoized<any> & Function = memoizee(original, Object.assign({}, DEFAULT_MEMOIZEE_OPTIONS, options));
  let _onError: (...args: any[]) => any = () => undefined;

  switch (options.onErrorStrategy) {
    case 'clear-all':
      _onError = newFunction.clear.bind(newFunction);
      break;
    case 'clear-current':
      _onError = newFunction.delete.bind(newFunction);
      break;
  }

  const name = `${target.constructor.name}[${propertyKey}]`;

  log(`Função memorizada: ${name}`);

  const onError = (error: Error, ...args: any[]): any => {
    log.extend('error')(`Ocorreu um erro em ${name}`, error);

    return _onError(args);
  };

  descriptor.value = function(...args: any[]): any {
    try {
      return newFunction.apply(this, args);
    } catch (error) {
      return onError(error, args);
    }
  };
};
