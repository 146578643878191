<span class="mat-list-item-content">
  <span class="mat-list-item-ripple" mat-ripple
       [matRippleTrigger]="_getHostElement()"
       [matRippleDisabled]="_isRippleDisabled()">
  </span>

  <ng-content select="[mat-list-avatar], [mat-list-icon], [matListAvatar], [matListIcon]">
  </ng-content>

  <span class="mat-list-text"><ng-content select="[mat-line], [matLine]"></ng-content></span>

  <ng-content></ng-content>
</span>
