import { Type } from '@imagine/common';
import { SyncMode } from './types';

export interface FileSyncOptions {
  relation?: string;
  linked?: boolean;
}

class SyncMetadata {
  entites: { target: Function; mode: SyncMode; indexedKeys?: string[] }[] = [];
  fileSync: {
    target: Function;
    propertyKey: string;
    options: FileSyncOptions;
    fileEntity: Type;
  }[] = [];
}

export default new SyncMetadata();
