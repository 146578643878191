import { Platform } from '@angular/cdk/platform';
import { PLATFORM_ID } from '@angular/core';

class PlatformConstants {
	constructor(private platform = new Platform(PLATFORM_ID)) {}

	get isMobile(): boolean {
		return this.platform.ANDROID || this.platform.IOS;
	}

	get isBrowser(): boolean {
		return !this.isMobile;
	}
}

export const PLATFORM = new PlatformConstants();
