import { Name } from 'app/decorators/name.decorator';
import { Sync } from 'app/decorators/sync.decorator';
import { Endpoint } from 'app/services/i-http-service';

@Sync('get')
@Endpoint('company/sizes')
@Name('Tamanho da empresa')
export class CompanySize {
  id: number;
  name: string;
}
