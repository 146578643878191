import { isFinite, isNumber } from 'lodash-es';

import { Memoize } from '../memoize';

interface ValueAndTotal {
  value: number;
  total: number;
}

export class MathUtil {
  static getPercentageOfValue({ value, total }: ValueAndTotal): number {
    return (value * 100) / total;
  }

  static parse(numeric: any): number {
    return Number.parseFloat(numeric);
  }

  // ? Arredonda um número para 2 casas decimais. Half-Down
  // Ex: 1.555 = 1.55
  // Ex2: 1.556 = 1.56
  // Ex3: 1.5551 = 1.56
  static round(value: number): number | null {
    if (typeof value !== 'number') {
      value = Number.parseFloat(value);
    }

    if (MathUtil.isInvalidNumber(value)) {
      return null;
    }

    return -Math.round(-value * 100) / 100;
  }

  @Memoize()
  static isInvalidNumber(value: number): boolean {
    return !isNumber(value) || !isFinite(value);
  }
}
