import { Name } from 'app/decorators/name.decorator';
import { Sync } from 'app/decorators/sync.decorator';
import { IsNumber, IsOptional, IsString } from 'class-validator';
import { Endpoint } from '../services/i-http-service';

@Sync('get', ['name', 'cpfCnpj'])
@Endpoint('people')
@Name('Pessoa')
export class People {
  @IsNumber()
  @IsOptional()
  id?: number;

  @IsString()
  cpfCnpj?: string;

  @IsString()
  name?: string;
}
