import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthInterceptor } from 'app/core/auth/auth.interceptor';
import { AuthService } from 'app/core/auth/auth.service';

import { UserService } from '../user/user.service';
import { UserStore } from '../user/user.store';

import { UserAccessService } from './user-access.service';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    AuthService,
    UserAccessService,
    UserService,
    UserStore,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
})
export class AuthModule {
  constructor(
    private readonly userService: UserService,
    private readonly authService: AuthService
  ) {
    this.initScheduler();
  }

  private initScheduler(): void {
    this.userStateSchedule();
  }

  // force get user from server in interval of 2 minutes
  private userStateSchedule(): void {
    this.loadUser();

    setInterval(() => {
      this.loadUser();
    }, 120_000);
  }

  private loadUser(): void {
    if (this.authService.isAuthenticated()) {
      this.userService.reload().catch((e) => {
        console.error('Não foi possível recarregar a sessão', e);
      });
    }
  }
}
