import { Type } from 'class-transformer';
import {
  IsBoolean,
  IsDefined,
  IsInt,
  IsJWT,
  IsNumber,
  IsOptional,
  IsPositive,
  IsString,
  ValidateIf,
} from 'class-validator';

import { UserAdmin } from '../../entities/user-admin.entity';
export class Auth {
  @IsBoolean()
  success: boolean;

  @ValidateIf((o: Auth) => !o.success)
  @IsString()
  message?: string;

  @ValidateIf((o: Auth) => o.success)
  @IsDefined()
  @Type(() => UserAdmin)
  user?: UserAdmin;

  @ValidateIf((o: Auth) => o.success)
  @IsJWT()
  token?: string;

  @IsOptional()
  @IsJWT()
  refreshToken?: string;

  @ValidateIf((o: Auth) => o.success)
  @IsNumber()
  @IsPositive()
  @IsInt()
  expiresIn?: number;
}
