import { Name } from 'app/decorators/name.decorator';
import { Sync } from 'app/decorators/sync.decorator';
import { Endpoint } from '../services/i-http-service';
import { ApplicationPerson } from './application-person.entity';
import { ApplicationGeolocation } from './application-geolocation.entity';
import { ApplicationAddress } from './application-address.entity';
import { ApplicationWitness } from './application-witness.entity';
import { ApplicationInvolved } from './application-involved.entity';
import { ApplicationInspector } from './application-inspector.entity';
import { ApplicationFile } from './application-file.entity';
import { InfractionLawParagraph } from './infraction-law-paragraph.entity';
import { InfractionDecreeArticle } from './infraction-decree-article.entity';
import { Type } from 'class-transformer';
import { FileSync } from 'app/decorators/file-sync.decorator';
import { ApplicationInvolvedSignature } from './application-involved-signature.entity';
import { ApplicationWitnessSignature } from './application-witness-signature.entity';
import { InfractionOtherLegislation } from './infraction-legislation-other.entity';
import { ApplicationAdditionalTerm } from './application-additional-term.entity';

export enum ApplicationBasedOn {
  LAW = 'law',
  DECREE = 'decree',
  OTHER = 'other',
}

@Sync('queue', ['folderNumber'])
@Endpoint('applications')
@Name('Infração')
export class Application {
  @FileSync(ApplicationWitnessSignature, { linked: true })
  witnessSignatures?: ApplicationWitnessSignature[];

  @FileSync(ApplicationFile, { linked: true })
  files: ApplicationFile[];

  folderNumber: string;
  notes?: string;
  reason: string;
  penaltyTypeId: number;
  companySizeId: number;
  penaltyAmount: number;
  additionalTerms?: ApplicationAdditionalTerm;

  person: ApplicationPerson;
  geolocation: ApplicationGeolocation;
  address: ApplicationAddress;
  witnesses: ApplicationWitness[];
  involved: ApplicationInvolved[];
  inspectors: ApplicationInspector[];
  lawParagraphs: InfractionLawParagraph[];
  decreeArticles: InfractionDecreeArticle[];
  otherLegislation: InfractionOtherLegislation;

  @FileSync(ApplicationInvolvedSignature, { linked: true })
  involvedSignatures?: ApplicationInvolvedSignature[];
}
