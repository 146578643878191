import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import debug from 'debug';

import { UserAdmin } from '../../entities/user-admin.entity';

@Injectable()
export class UserAccessService {
  private readonly log = debug(UserAccessService.name);

  canAccess(user: UserAdmin, route: ActivatedRouteSnapshot): boolean {
    this.log('@canAccess => ', user, route);

    // bypass administrador
    return true;
  }
}
