import { merge } from 'lodash-es';

import { Callback } from '../interfaces';

interface PromiseEachOptions {
  // Deve esperar um tempo para iniciar a próxima promise
  shouldSleep?: boolean;
  // Tempo de espera para executar a proxima promise
  sleepTime?: number;
}

export class PromiseHelper {
  private static readonly DEFAULT_FOREACH_OPTIONS: PromiseEachOptions = Object.freeze({
    shouldSleep: true,
    sleepTime: 300
  });

  static async sleep(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  static async forEach<R, T>(arr: T[], fn: Callback<T, R>, options: PromiseEachOptions = {}): Promise<void> {
    options = merge({}, PromiseHelper.DEFAULT_FOREACH_OPTIONS, options);

    for (const obj of arr) {
      await fn(obj);

      if (options.shouldSleep) {
        await PromiseHelper.sleep(options.sleepTime);
      }
    }
  }

  static executeSync(fn: () => Promise<any>): void {
    fn().catch(console.error);
  }
}
