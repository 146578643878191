import { Injectable } from '@angular/core';
import debug from 'debug';
import { ISpinnerService } from '@imagine/i-spinner';
import { AuthService } from 'app/core/auth/auth.service';
import { UserService } from 'app/core/user/user.service';
import { HttpService } from 'app/services/http.service';
import { ENTITY_METADATA } from 'app/services/i-http-service/constants';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { MAX_STACK_SUCCESS_SIZE } from './constants';
import { AppDB } from './db.service';
import syncMetadata from './sync.metadata';
import { Store } from '@ngxs/store';
import { UpdateNetworkConnected } from '../app/store/sync/update-network-connected.state';
import { ClassConstructor, plainToClass } from 'class-transformer';
import { EntityService } from '@imagine/dynamic-http-service';
import { createContentIntegrity } from '@imagine/i-form/i-form-upload/helper/create-content-integrity';
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { USE_AUTHENTICATION_TOKEN } from 'app/core/auth';
import { dataURItoBlob } from '@imagine/i-form/i-form-upload/helper/data-uri-to-blob';
import { FileSyncBaseParams } from './interfaces/file-sync-base-params.interface';
import { captureException } from '@sentry/angular';
import { cloneDeep, pick } from 'lodash';
import { environment } from 'environments/environment';

@Injectable()
export class SyncService {
  private readonly log = debug(SyncService.name);

  constructor(
    private userService: UserService,
    private httpService: HttpService,
    private authService: AuthService,
    private db: AppDB,
    private spinnerService: ISpinnerService,
    private httpClient: HttpClient,
    private store: Store
  ) {}

  public setNetworkConnected(isNetworkConnected: boolean): void {
    this.store.dispatch(new UpdateNetworkConnected(isNetworkConnected));

    this.sync();
  }

  /**
   * Inicia o processo de sincronização
   *
   * @returns void
   */
  public async sync(): Promise<void> {
    const isNetworkConnected = this.store.selectSnapshot<string>(
      (state) => state.sync.networkConnected
    );

    if (isNetworkConnected) {
      this.log('Network connected, syncing...');
    } else {
      this.log('Network disconnected, cant sync');
      return;
    }

    if (!this.authService.isAuthenticated()) {
      return;
    }

    try {
      this.spinnerService
        .setText(
          'Sincronizando... Isso pode levar alguns instantes... Não saia no app ou feche o aplicativo!'
        )
        .show();
      for (const entityMetadata of syncMetadata.entites) {
        const { table, endpoint } = this.getMetadata(entityMetadata.target);

        switch (entityMetadata.mode) {
          case 'queue':
            // await this.syncQueue({ table, entityMetadata, endpoint });
            await this.cleanQueue(table);
            break;
          case 'get':
            await this.syncGet({ table, entityMetadata, endpoint });
            break;
          case 'get-and-queue':
            // TODO: implement
            break;
          default:
            throw new Error('Mode not found');
        }
      }
    } catch (err) {
      this.log('Não foi possível sincronizar tudo', err);
    }
    this.spinnerService.hide();
  }

  /**
   * Utilizado para sincronizar um item especifico ao invés da queue toda
   * Ex: ao clicar no botao de sincronizar um item especifico na tela de sincronização
   *
   * @param id number
   * @param entityMetadata Function
   * @returns void
   */
  public async syncItemId<T>(
    id: number,
    useClass: ClassConstructor<T>,
    { force }: { force?: boolean } = {}
  ): Promise<void> {
    try {
      this.spinnerService.setText(
        'Sincronizando um item... Não feche o aplicativo.'
      );
      this.spinnerService.show();

      if (!this.authService.isAuthenticated()) {
        throw new Error('User not authenticated');
      }

      const entityMetadata = syncMetadata.entites.find(
        ({ target }) => target === useClass
      );

      if (
        !entityMetadata ||
        !entityMetadata.mode ||
        entityMetadata.mode !== 'queue'
      ) {
        throw new Error('Entity not found or not in queue mode');
      }

      const { table, endpoint } = this.getMetadata(useClass);

      const user = await this.userService.get();
      const item = await table.get(id);

      item.payload = plainToClass(useClass, item.payload);

      await this.syncItem({
        table,
        item,
        entityMetadata,
        endpoint,
        user,
        force,
      });
    } finally {
      this.spinnerService.hide();
    }
  }

  /**
   * Pega os metadados de uma entidade
   *
   * @param useClass Function
   * @returns void
   */
  private getMetadata(useClass): { table: any; endpoint: string } {
    const table = Reflect.get(this.db, useClass.name);
    const endpoint = Reflect.getMetadata(ENTITY_METADATA, useClass);

    return { table, endpoint };
  }

  /**
   * Sincroniza tabelas apenas de dados
   *
   * @param param0 { table: Table<Syncable<any>, number>, useClass: Function, endpoint: string }
   */
  private async syncGet({ table, entityMetadata, endpoint }): Promise<void> {
    try {
      const { data } = await this.httpService
        .as(entityMetadata.target)
        .get(endpoint, {
          params: {
            getAll: true,
          },
        });
      this.log(`${data.length} items mapeados para ${table.name}`);

      // Limpa a tabela antes de inserir os dados
      await table.clear();
      await table.bulkAdd(data);
    } catch (err) {
      this.log(
        'Não foi possível sincronizar a tabela de dados',
        table.name,
        err
      );
    }
  }

  /**
   * Sincroniza uma queue
   *
   * @param param0 { table: Table<Syncable<any>, number>, endpoint: string }
   */
  private async syncQueue({ table, entityMetadata, endpoint }): Promise<void> {
    const user = await this.userService.get();
    for (const item of await table.toArray()) {
      item.payload = plainToClass(entityMetadata.target, item.payload);
      await this.syncItem({ table, item, entityMetadata, endpoint, user });
    }
  }

  /**
   * Sincroniza um item de uma queue
   *
   * @param param0 { table: Table<Syncable<any>, number>, item: Syncable<any>, endpoint: string, user: User }
   * @returns
   */
  private async syncItem({
    table,
    item,
    entityMetadata,
    endpoint,
    user,
    force = false,
  }): Promise<void> {
    try {
      table.update(item.id, {
        lastSync: new Date(),
        syncTries: (item?.syncTries || 0) + 1,
        forcedSync: item?.forcedSync || force,
        lastSyncForced: force,
        lastTriedVersion: environment.version,
      });

      // skip if is success or syncing
      if (
        !force &&
        (item.status === 'success' ||
          item.status === 'syncing' ||
          item.status === 'syncing-files' ||
          user.id !== item.userId) // skip if is not the user that created the item
      ) {
        this.log(`${item.id} skipped`);
        return;
      }

      if (
        force ||
        (item.status !== 'error-files' && item.status !== 'request-success')
      ) {
        // update status to syncing
        await table.update(item.id, { status: 'syncing' });

        const fileSyncMetadatas = syncMetadata.fileSync.filter(
          ({ target }) => target === entityMetadata.target
        );

        const payload = this.cleanPayload(item.payload, fileSyncMetadatas);

        const response = await firstValueFrom(
          this.httpService.post(endpoint, payload)
        );

        table.update(item.id, {
          response,
          status: 'request-success',
        });

        item.response = response;
      }
    } catch (err) {
      console.error(err);
      if (err?.status !== undefined && err?.status !== null) {
        table.update(item.id, {
          lastErrorAt: 'item',
          lastErrorMessage: err?.message,
          lastErrorCode: err.status?.toString(),
        });
      }

      // se o back retornar que a sincronização pode continuar
      // então busca os dados do item no back e atualiza o payload e tenta novamente
      if (
        err?.error?.action === 'continue' &&
        entityMetadata.indexedKeys.length
      ) {
        const payload = pick(item.payload, ...entityMetadata.indexedKeys);

        const response = await firstValueFrom(
          this.httpService.get(`${endpoint}/metadata`, payload)
        );

        table.update(item.id, {
          response,
        });
      } else {
        captureException(err, {
          tags: {
            type: 'item',
            uuid: item.uuid,
          },
          extra: {
            localId: item.id,
            item,
            payload: item.payload,
            bodyMessage: err?.error?.message || err?.error,
          },
        });

        this.log('Não foi possível sincronizar um item', err);

        table.update(item.id, {
          status: 'error',
        });

        return;
      }
    }

    try {
      await this.syncFiles({ table, entityMetadata, item });

      table.update(item.id, {
        status: 'success',
      });
    } catch (err) {
      console.error(err);
      if (err?.status !== undefined && err?.status !== null) {
        table.update(item.id, {
          lastErrorAt: 'files',
          lastErrorMessage: err?.message,
          lastErrorCode: err.status?.toString(),
        });
      }

      captureException(err, {
        tags: {
          type: 'files',
          uuid: item.uuid,
        },
        extra: {
          localId: item.id,
          item,
          payload: item.payload,
          bodyMessage: err?.error?.message || err?.error,
        },
      });

      console.log('Não foi possível sincronizar os arquivos de um item', err);

      table.update(item.id, {
        status: 'error-files',
      });
    }
  }

  private cleanPayload(item, fileSyncMetadatas): any {
    const payload = cloneDeep(item);

    for (const fileSyncMetadata of fileSyncMetadatas) {
      const files = Reflect.get(payload, fileSyncMetadata.propertyKey);

      if (files) {
        for (const file of files) {
          delete file.content;
        }
      }
    }

    return payload;
  }

  private async syncFiles({ table, entityMetadata, item }): Promise<void> {
    const fileSyncMetadatas = syncMetadata.fileSync.filter(
      ({ target }) => target === entityMetadata.target
    );

    for (const fileSyncMetadata of fileSyncMetadatas) {
      const entityService = new EntityService<any>(fileSyncMetadata.fileEntity);

      const files = Reflect.get(item.payload, fileSyncMetadata.propertyKey);

      // se tiver algum arquivo a ser sincronizado
      if (files) {
        // update item status to syncing-files
        table.update(item.id, {
          status: 'syncing-files',
        });

        for (const file of files) {
          const baseParams: FileSyncBaseParams = {
            id: item.response.id,
          };

          if (fileSyncMetadata.options?.linked) {
            const linkedItems = Reflect.get(
              item.response,
              fileSyncMetadata.propertyKey
            );

            const linkedItem = linkedItems.find(
              (_item: any) => _item.fileSyncKey === file.fileSyncKey
            );

            if (!linkedItem) {
              throw new Error(
                'Não foi possível encontrar o item linkado ao arquivo'
              );
            }

            baseParams.linkedId = linkedItem?.id;
          }

          const fileBlob = dataURItoBlob(file.content);

          const integrity = await createContentIntegrity(fileBlob);

          const { url } = await entityService.setBaseParams(baseParams).post({
            filename: file.filename,
            fileSyncKey: file.fileSyncKey,
            mimeType: file.mimeType,
            simplifiedType: file.simplifiedType,
            size: file.size,
            integrity,
          });

          await lastValueFrom(
            this.httpClient.put(url, fileBlob, {
              context: new HttpContext().set(USE_AUTHENTICATION_TOKEN, false),
              headers: new HttpHeaders().set('Content-Type', file.mimeType),
            })
          );
        }
      }
    }
  }

  /**
   * Limpa a tabela de sincronização (apenas deve ser chamado se for uma queue)
   *
   * @param table Table<Syncable<any>, number>
   */
  private async cleanQueue(table): Promise<void> {
    const items = await table
      .where({ status: 'success' })
      .offset(MAX_STACK_SUCCESS_SIZE)
      .reverse()
      .toArray();

    const itemsToDelete = items.map(({ id }) => id);

    await table.bulkDelete(itemsToDelete);
  }
}
