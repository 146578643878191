import { Injectable } from '@angular/core';
import { Selector, State } from '@ngxs/store';

type InfractionStateFormModel = {
  InfractionForm: {
    model: any; // TODO: TIPAR
    dirty: boolean;
    status?: string;
    errors?: { [k: string]: string };
  };
};

@State<InfractionStateFormModel>({
  name: 'infraction',
  defaults: {
    InfractionForm: {
      model: undefined,
      dirty: false,
      status: '',
      errors: {},
    },
  },
})
@Injectable()
export class InfractionState {
  @Selector()
  static getInfractionForm(state: InfractionStateFormModel) {
    return state.InfractionForm;
  }
}
