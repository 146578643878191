import { HttpErrorResponse } from '@angular/common/http';

import { AuthError } from './auth-error';

export class InvalidCredentialsError extends AuthError {
  constructor(error: Error) {
    let message = 'Ocorreu um erro ao tentar realizar o login';

    if (error instanceof HttpErrorResponse) {
      if (error.status === 0) {
        message = 'Não foi possível se conectar com o servidor.';
      } else {
        message = Array.isArray(error.error.message) ? error.error.message[0] : error.error.message;
      }
    }

    super(message, error);
  }
}
