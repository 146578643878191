import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CriteriaResult } from '../../../app/utils/useFilterCriteria';
import { EntityService } from '../../dynamic-http-service';

import { Criteria } from './criteria';

export class CriteriaEntityServiceLink<Entity = any> {
  private subscription?: Subscription;
  private subject = new Subject<CriteriaResult<Entity>>();

  constructor(private readonly criteria: Criteria<Entity>, private readonly entityService: EntityService<Entity>) {
  }

  requestOnChange(destroy$: Subject<any>): Observable<CriteriaResult<Entity>> {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }

    this.subscription = this.criteria.optionsChange
      .pipe(takeUntil(destroy$))
      .subscribe(() => {
        this.entityService.get(this.criteria)
          .then(result => this.subject.next(result))
          .catch(error => this.subject.error(error));
      });

    return this.subject.asObservable();
  }
}
