import { Name } from 'app/decorators/name.decorator';
import { Sync } from 'app/decorators/sync.decorator';
import { Endpoint } from '../services/i-http-service';
import { LawParagraph } from './law-paragraph.entity';

@Sync('get')
@Endpoint('decree')
@Name('Decreto')
export class DecreeSection {
  id: number;
  description: string;
  articles?: LawParagraph[];
}
