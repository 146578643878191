<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="userActions">
  <span class="relative">
    <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
  </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
  <button mat-menu-item>
    <span class="flex flex-col leading-none">
      <span>Logado como</span>
      <span class="mt-1.5 text-md font-medium">{{user.name}}</span>
    </span>
  </button>

  <button mat-menu-item>
    Versão: {{version}}
  </button>
  <!-- <mat-divider class="my-2"></mat-divider> -->
  <!-- <button mat-menu-item>
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>Profile</span>
    </button>
    <button mat-menu-item>
        <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
        <span>Settings</span>
    </button>
    <button mat-menu-item [matMenuTriggerFor]="userStatus">
        <mat-icon [svgIcon]="'heroicons_outline:dots-circle-horizontal'"></mat-icon>
        <span>Status</span>
    </button> -->
  <mat-divider class="my-2"></mat-divider>
  <button mat-menu-item (click)="signOut()">
    <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
    <span>Sair</span>
  </button>
</mat-menu>

<mat-menu class="user-status-menu" #userStatus="matMenu">
  <button mat-menu-item (click)="updateUserStatus('online')">
    <span class="w-4 h-4 mr-3 rounded-full bg-green-500"></span>
    <span>Online</span>
  </button>
  <button mat-menu-item (click)="updateUserStatus('away')">
    <span class="w-4 h-4 mr-3 rounded-full bg-amber-500"></span>
    <span>Away</span>
  </button>
  <button mat-menu-item (click)="updateUserStatus('busy')">
    <span class="w-4 h-4 mr-3 rounded-full bg-red-500"></span>
    <span>Busy</span>
  </button>
  <button mat-menu-item (click)="updateUserStatus('not-visible')">
    <span class="w-4 h-4 mr-3 rounded-full bg-gray-400"></span>
    <span>Invisible</span>
  </button>
</mat-menu>