import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SyncService } from '@sync/sync.service';
import { AppModule } from 'app/app.module';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserAdmin } from '../../entities/user-admin.entity';

import { UserStore } from './user.store';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private readonly store: UserStore
  ) {}

  private _user: ReplaySubject<UserAdmin> = new ReplaySubject<UserAdmin>(1);

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set user(value: UserAdmin) {
    this.store.store(value);
    // Store the value
    this._user.next(value);

    const service = AppModule.injector().get(SyncService);
    service.sync();
  }

  get user$(): Observable<UserAdmin> {
    return this._user.asObservable();
  }

  getSnapshot(): UserAdmin {
    return this.store.snapshot;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the current logged in user data
   */
  async get(): Promise<UserAdmin> {
    const user = await this.store.get();
    this._user.next(user);
    return user;
  }

  async reload(): Promise<UserAdmin> {
    const user = await this.store.get({ force: true });
    this._user.next(user);
    return user;
  }

  /**
   * Update the user
   *
   * @param user
   */
  update(user: UserAdmin): Observable<any> {
    return this._httpClient.patch<UserAdmin>('api/common/user', { user }).pipe(
      map((response) => {
        this._user.next(response);
      })
    );
  }
}
