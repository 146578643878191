import { Searchable } from '@imagine/common/criteria';
import { Status } from 'app/core/core.enum';
import { Name } from 'app/decorators/name.decorator';
import { Sync } from 'app/decorators/sync.decorator';
import {
  IsDate,
  IsEmail,
  IsEnum,
  IsNumber,
  IsOptional,
  IsString,
} from 'class-validator';

import { Endpoint } from '../services/i-http-service';

@Endpoint('user/admin')
@Sync('get', ['name', 'cpf'])
@Name('Fiscal')
export class UserAdmin {
  @IsNumber()
  @IsOptional()
  id: number;

  @IsEnum(Status)
  status: Status;

  @IsEmail({}, { message: 'Digite um e-mail válido' })
  @Searchable()
  email: string;

  @IsString()
  @Searchable()
  name: string;

  @IsString()
  cpf: string;

  @IsString()
  @IsOptional()
  registrationNumber: string;

  @IsOptional()
  @IsString()
  password?: string;

  @IsDate()
  createdAt: Date;

  @IsDate()
  updatedAt: Date;

  @IsDate()
  deletedAt: Date;
}
