import {
  APP_INITIALIZER,
  ErrorHandler,
  Injector,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  ExtraOptions,
  PreloadAllModules,
  Router,
  RouterModule,
} from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { Store } from '@ngxs/store';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { HttpService } from './services/http.service';
import { ImagineSnack } from '@imagine/services/imagine-snack.service';
import { ISpinnerService } from '@imagine/i-spinner';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { StoreModule } from './store';
import { FooterActionsModule } from './components/footer-actions/footer-actions.module';
import { FuseConfirmationModule } from '@fuse/services/confirmation';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { SyncModule } from '@sync/sync.module';
import { EntityLoaderModule } from './core/entity-loader.module';
import { SheetMultipleChoiceModule } from './layout/common/sheet-multiple-choice/sheet-multiple-choice.module';
import { SheetService } from '@imagine/services/sheet.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { IonicModule } from '@ionic/angular';
import { BluetoothLE } from '@awesome-cordova-plugins/bluetooth-le/ngx';
import { createErrorHandler, TraceService } from '@sentry/angular';

registerLocaleData(localePt);

const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled',
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, routerConfig),
    StoreModule,

    // Fuse, FuseConfig & FuseMockAPI
    FuseModule,
    FuseConfigModule.forRoot(appConfig),
    FuseMockApiModule.forRoot(mockApiServices),

    MatSnackBarModule,
    NgxSpinnerModule,

    // Core module of your application
    CoreModule,

    // Layout module of your application
    LayoutModule,
    FooterActionsModule,

    // 3rd party modules that require global configuration via forRoot
    MarkdownModule.forRoot({}),
    FuseConfirmationModule,
    SheetMultipleChoiceModule,

    EntityLoaderModule,
    SyncModule,
    IonicModule.forRoot(),
  ],
  bootstrap: [AppComponent],
  providers: [
    HttpService,
    ImagineSnack,
    ISpinnerService,
    BluetoothLE,
    SheetService,
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => (): void => {},
      deps: [TraceService],
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
  ],
})
export class AppModule {
  private static _injetor: Injector;
  private static _store: Store;

  constructor(injector: Injector, store: Store) {
    AppModule._injetor = injector;
    AppModule._store = store;
  }

  public static injector(): Injector {
    return AppModule._injetor;
  }
  public static store(): Store {
    return AppModule._store;
  }
}
