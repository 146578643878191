import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import debug from 'debug';

import { EntityWithPosition, orderByPosition } from './order-by-position';

const log = debug('orderInDragEvent');

interface HandleEventDragOrder<Entity extends EntityWithPosition> {
  event: CdkDragDrop<Entity>;
  items: Entity[];
}

export const orderInDragEvent = <Entity extends EntityWithPosition>({
                                                                      event,
                                                                      items
                                                                    }: HandleEventDragOrder<Entity>): Entity[] => {
  // ? Ta movendo de cima para baixo se a distancia Y for maior que 0
  const isUp2Down = event.distance.y > 0;

  log('isUp2Down', isUp2Down);

  log('event', event);

  // Item que está sendo movido
  const current = items[event.previousIndex];

  // Para onde o item vai
  const target = items[event.currentIndex];

  const newPosition = target.position;

  log('current', current);
  log('target', target);

  current.position = newPosition;
  target.position = isUp2Down ? newPosition - 1 : newPosition + 1;

  log('currentPosition', current.position);
  log('targetPosition', target.position);

  moveItemInArray(
    items,
    event.previousIndex,
    event.currentIndex
  );

  return orderByPosition(items);
};
