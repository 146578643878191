import 'reflect-metadata';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from 'environments/environment';
import { AppModule } from 'app/app.module';
import { App } from '@capacitor/app';
import { BrowserTracing } from '@sentry/tracing';
import {
  init,
  makeBrowserOfflineTransport,
  makeFetchTransport,
  routingInstrumentation,
  Replay,
} from '@sentry/angular';
import { HttpErrorResponse } from '@angular/common/http';

init({
  dsn: 'https://7cdbba5b52bc4756bac922362505139b@o492405.ingest.sentry.io/4504221233315840',
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: [
        /https:\/\/[a-zA-Z]+-api\.autoinfracao\.ima\.imagineapps\.com\.br\//i,
      ],
      routingInstrumentation: routingInstrumentation,
    }),
    new Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  release: environment.version,
  dist: environment.version,
  transport: makeBrowserOfflineTransport(makeFetchTransport),
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  beforeSend: (event) => {
    if (event.extra) {
      event.extra.angularEnvironment = environment;
    }
    if (event.extra?.from && event.extra?.from === 'AbstractSnackMessage') {
      event.message = event.extra.message as string;
    }

    // Note: issue with double entries during http exceptions: https://github.com/getsentry/sentry-javascript/issues/2169
    // Note: issue with a second entry not being set correctly (as a non-error): https://github.com/getsentry/sentry-javascript/issues/2292#issuecomment-554932519
    if (
      event.exception &&
      event.exception.values &&
      event.exception.values.length > 0
    ) {
      const exceptionValue =
        event.exception.values[0].value ?? 'Exception value not found';
      const isNonErrorException = exceptionValue.startsWith(
        'Non-Error exception captured'
      );

      if (isNonErrorException) {
        if (!event.extra.__serialized__) {
          return null;
        }

        const serialized = event.extra.__serialized__ as HttpErrorResponse;
        let realErrMsg = serialized.error ? serialized.error.message : null;
        realErrMsg = serialized.message;
        // this is a useless error message that masks the actual error.  Lets try to set it properly
        event.exception.values[0].value = realErrMsg;
        event.message = realErrMsg;
      }
    }

    return event;
  },

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

App.addListener('backButton', () => {
  console.log(
    'User pushed the back button, default behaviour has been overiden'
  );
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
