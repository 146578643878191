/* eslint-disable @typescript-eslint/naming-convention */
export enum Status {
  DELETED,
  ACTIVE,
  PENDING,
  DISABLED,
}

export enum PerfilId {
  ADMINISTRADOR = 1,
  USER,
}
