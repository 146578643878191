import {
  Device,
  DeviceInfo,
  Status,
} from '@awesome-cordova-plugins/bluetooth-le/ngx';

export class BluetoothDevice implements DeviceInfo {
  device?: Device;
  status: Status;
  /** The device's display name */
  name: string;
  /** The device's address / identifier for connecting to the object */
  address: string;
}
