<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>
<!--
<fuse-vertical-navigation class="dark bg-gray-900 print:hidden" [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'" [navigation]="navigation.default" [opened]="!isScreenSmall">

    <ng-container fuseVerticalNavigationContentHeader>
        <div class="flex items-center w-full p-4 pl-6">
            <div class="flex items-center justify-center">
                <img class="w-8" src="assets/images/logo/logo.svg">
            </div>
            <div class="flex items-center ml-auto">
                <notifications></notifications>

            </div>
        </div>
        <div class="flex flex-col items-center w-full p-4">
            <div class="relative w-24 h-24">
                <i-avatar [userName]="user.name" [size]="AvatarSize.FULL"></i-avatar>
                <mat-icon class="icon-size-24" *ngIf="!user.name" [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
            </div>
            <div class="flex flex-col items-center justify-center w-full mt-6">
                <div
                    class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium">
                    {{user.name}}
                </div>
                <div
                    class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary">
                    {{user.email}}
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container fuseVerticalNavigationContentFooter>
        <div class="flex flex-0 items-center justify-center h-16 pr-6 pl-2 mt-2 mb-4 opacity-12">
            <img class="max-w-36" src="assets/images/logo/logo-text-on-dark.svg">
        </div>
    </ng-container>
</fuse-vertical-navigation> -->

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0 overflow-auto">

    <!-- Header -->
    <div
        class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">

        <mat-icon class="ml-2" *ngIf="isNetworkConnected; else notConnected" matTooltip="Internet Conectada">public</mat-icon>

        <ng-template #notConnected>
            <mat-icon>public_off</mat-icon>
        </ng-template>

        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <user [showAvatar]="true"></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>
</div>

<!-- Quick chat -->
<!-- <quick-chat #quickChat="quickChat"></quick-chat> -->
