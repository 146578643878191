import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { UserStore } from 'app/core/user/user.store';
import debug from 'debug';
import { Observable } from 'rxjs';

import { UserAccessService } from '../user-access.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  private readonly log = debug(AuthGuard.name);

  /**
   * Constructor
   */
  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _userStore: UserStore,
    private _userAccessService: UserAccessService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Can activate
   *
   * @param route
   * @param state
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> | boolean {
    const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
    return this._check(redirectUrl, route);
  }

  /**
   * Can activate child
   *
   * @param childRoute
   * @param state
   */
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
    return this._check(redirectUrl, childRoute);
  }

  /**
   * Can load
   *
   * @param route
   * @param segments
   */
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._check('/');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Check the authenticated status
   *
   * @param redirectURL
   * @param route Rota atualmente ativa
   * @private
   */
  private async _check(
    redirectURL: string,
    route?: ActivatedRouteSnapshot
  ): Promise<boolean> {
    this.log('rota atual : ', route?.toString());
    this.log(
      'rota anterior : ',
      this._router
        .getCurrentNavigation()
        ?.previousNavigation?.extractedUrl?.toString()
    );

    this.log('Verificando se usuário está autenticado...');
    // Check the authentication status
    const authenticated = await this._authService.check();

    this.log('Autenticado : ', authenticated);

    // If the user is not authenticated...
    if (!authenticated) {
      this.log('Redirecionando para sign-in');
      // Redirect to the sign-in page
      this._router.navigate(['sign-in'], { queryParams: { redirectURL } });

      // Prevent the access
      return false;
    }

    const user = await this._userStore.get();

    const canAccess = this._userAccessService.canAccess(user, route);

    if (canAccess) {
      this.log('acesso permitido');
      return true;
    }

    this.log('Acesso não permitido');

    this._router.navigate(['signed-in-redirect']);

    return false;
  }
}
