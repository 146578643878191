import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { USE_AUTHENTICATION_TOKEN } from './constants';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  /**
   * Constructor
   */
  constructor(
    private _authService: AuthService,
    private readonly router: Router
  ) {}

  /**
   * Intercept
   *
   * @param req
   * @param next
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Clone the request object
    let newReq = req.clone();

    // Request
    //
    // If the access token didn't expire, add the Authorization header.
    // We won't add the Authorization header if the access token expired.
    // This will force the server to return a "401 Unauthorized" response
    // for the protected API routes which our response interceptor will
    // catch and delete the access token from the local storage while logging
    // the user out from the app.
    if (this._authService.accessToken) {
      const useAuthentication = req.context.get(USE_AUTHENTICATION_TOKEN);

      if (useAuthentication) {
        const token = 'Bearer ' + this._authService.accessToken;

        newReq = req.clone({
          headers: req.headers.set('Authorization', token),
        });
      }
    }

    // Response
    return next.handle(newReq).pipe(
      catchError((error) => {
        // Catch "401 Unauthorized" responses
        if (error instanceof HttpErrorResponse && error.status === 401) {
          // ? Se tiver goTo, é por que o servidor tem ações customizadas para permitir a autenticação
          if (error.error.goTo) {
            this.router.navigate([error.error.goTo]);
          } else {
            // Sign out
            this._authService.signOut();
            // Reload the app
            this.router.navigate(['/sign-in']);
          }
        }

        return throwError(error);
      })
    );
  }
}
