import {
	CUSTOM_ELEMENTS_SCHEMA,
	NgModule,
	NO_ERRORS_SCHEMA,
} from '@angular/core';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { HttpService } from 'app/services/http.service';
import { SheetMultipleChoiceComponent } from './sheet-multiple-choice.component';

@NgModule({
	declarations: [SheetMultipleChoiceComponent],
	imports: [
		MatDialogModule,
		MatButtonModule,
		MatDividerModule,
		MatIconModule,
		MatBottomSheetModule,
		MatListModule,
	],
	providers: [HttpService],
	entryComponents: [SheetMultipleChoiceComponent],
	schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
})
export class SheetMultipleChoiceModule {}
