import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FooterActionsModule } from 'app/components/footer-actions/footer-actions.module';

import { BreadcrumbModule } from './breadcrumb/breadcrumb.module';
import { PageLayoutModule } from './page-layout/page-layout.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    PageLayoutModule,
    FooterActionsModule,
    BreadcrumbModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PageLayoutModule,
    FooterActionsModule,
  ],
})
export class SharedModule {}
