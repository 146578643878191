import { Name } from 'app/decorators/name.decorator';
import { Sync } from 'app/decorators/sync.decorator';
import { Type } from 'class-transformer';
import { IsNumber, IsOptional, IsString } from 'class-validator';
import { Endpoint } from '../services/i-http-service';

@Sync('get', ['name', 'federalUnitId'])
@Endpoint('people/cities')
@Name('Cidade')
export class PeopleCity {
  @Type(() => Number)
  @IsNumber()
  @IsOptional()
  id?: number;

  @IsNumber()
  federalUnitId?: number;

  @IsString()
  name?: string;
}
