import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TextOnlySnackBar } from '@angular/material/snack-bar';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { FuseAlertAppearance, FuseAlertType } from '@fuse/components/alert';
import { ISnackbarComponent } from '@imagine/i-snackbar/i-snackbar.component';

import { PLATFORM } from '../../constants/platform';

const durationInSeconds = 5;

export type SnackOptions = MatSnackBarConfig & {
  action?: string;
  actionCallback?: () => void;
};
export type SnackComponentOptions = {
  type?: FuseAlertType;
  appearance?: FuseAlertAppearance;
  showIcon?: boolean;
  title?: string;
};

@Injectable()
export class ImagineSnack {
  constructor(private matSnackBar: MatSnackBar) {}

  /**
   *
   * @param message Mensagem para ser exibida
   * @param duration Duração em segundos do snackbar
   * @param options  Opções do snackbar
   * @param options.actionCallback Callback para o evento de click no botão de ação
   * @param options.action Texto do botão de ação
   * @param options.verticalPosition Posição vertical do snackbar
   * @param options.horizontalPosition Posição horizontal do snackbar
   *
   * @returns void
   */
  openMaterial(
    message: string,
    options: SnackOptions = { duration: durationInSeconds }
  ): MatSnackBarRef<TextOnlySnackBar> {
    const {
      verticalPosition = PLATFORM.isBrowser ? 'top' : 'bottom',
      horizontalPosition = 'center',
      action,
      actionCallback,
    } = options || {};

    const snackBarRef = this.matSnackBar.open(message, action, {
      duration: options.duration * 1000,
      verticalPosition,
      horizontalPosition,
    });

    if (action) {
      snackBarRef.onAction().subscribe(() => {
        actionCallback();
      });
    }

    return snackBarRef;
  }

  /**
   * Exibe um snackbar com um componente customizado
   *
   * @param message Mensagem para ser exibida
   * @param snackComponentOptions Opções do componente do Snackbar
   * @param snackComponentOptions.type Tipo do snackbar
   * @param snackComponentOptions.appearance Aparência do snackbar
   * @param snackComponentOptions.showIcon Exibe o ícone do snackbar
   * @param snackComponentOptions.title Título do snackbar
   * @param options Opções do snackbar
   * @param options.duration Duração do snackbar
   * @param options.actionCallback Callback para o evento de click no botão de ação
   * @param options.action Texto do botão de ação
   * @param options.verticalPosition Posição vertical do snackbar
   * @param options.horizontalPosition Posição horizontal do snackbar
   * @returns void
   * @example
   * ImagineSnack.open('message', {
   *    type: 'success',
   *    title: 'Título do snackbar', // não obrigatório
   * }, {
   *    duration: 5, // duração em segundos, default para 5
   *    action: 'Ação',
   *    actionCallback: () => {
   *      console.log('Ação do snackbar');
   *    },
   * });
   * */
  open(
    message: string,
    snackComponentOptions?: SnackComponentOptions,
    options: SnackOptions = { duration: durationInSeconds }
  ): MatSnackBarRef<ISnackbarComponent> {
    const {
      verticalPosition = PLATFORM.isBrowser ? 'top' : 'bottom',
      horizontalPosition = 'center',
      action,
      actionCallback,
    } = options || {};

    const {
      type = 'info',
      appearance = 'border',
      showIcon = true,
      title,
    } = snackComponentOptions || {};

    const snackBarRef = this.matSnackBar.openFromComponent(ISnackbarComponent, {
      duration: options.duration * 1000,
      verticalPosition,
      horizontalPosition,
      panelClass: ['bg-transparent', 'shadow-none', '-mx-4'],
      data: {
        message,
        appearance,
        type,
        showIcon,
        title: title === undefined ? this.getDefaultTitle(type) : title,
      },
    });

    if (action) {
      snackBarRef.onAction().subscribe(() => {
        actionCallback();
      });
    }

    return snackBarRef;
  }

  getDefaultTitle(type: FuseAlertType): string {
    switch (type) {
      case 'success':
        return 'Sucesso';
      case 'info':
        return 'Informação';
      case 'warning':
        return 'Atenção';
      case 'warn':
        return 'Atenção';
      case 'error':
        return 'Erro';
      default:
        return 'Informação';
    }
  }

  close(snackBarRef: MatSnackBarRef<TextOnlySnackBar>): void {
    snackBarRef.dismiss();
  }

  closeAll(): void {
    this.matSnackBar.dismiss();
  }
}
