import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import debug from 'debug';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NoAuthGuard implements CanActivate, CanActivateChild, CanLoad {
  private readonly log = debug(NoAuthGuard.name);

  /**
   * Constructor
   */
  constructor(private _authService: AuthService, private _router: Router) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Can activate
   *
   * @param route
   * @param state
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._check();
  }

  /**
   * Can activate child
   *
   * @param childRoute
   * @param state
   */
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._check();
  }

  /**
   * Can load
   *
   * @param route
   * @param segments
   */
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._check();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Check the authenticated status
   *
   * @private
   */
  private async _check(): Promise<boolean> {
    // Check the authentication status
    const authenticated = await this._authService.check();
    this.log('usuário está autenticado ?', authenticated);

    // If the user is authenticated...
    if (authenticated) {
      this.log('redirecionando para signed-in-redirect');
      // Redirect to route who control redirect based on profile
      this._router.navigate(['signed-in-redirect']);

      // Prevent the access
      return false;
    }

    this.log(
      'habilitando o acesso a rota',
      this._router.getCurrentNavigation()?.extractedUrl?.toString() ||
        'EMPTY NAVIGATION'
    );
    this.log(
      'rota anterior',
      this._router
        .getCurrentNavigation()
        ?.previousNavigation?.extractedUrl?.toString() || 'EMPTY NAVIGATION'
    );

    // Allow the access
    return true;
  }
}
