import { Size } from 'ngx-spinner';

import { SpinnerOptions } from './interfaces';

export const DEFAULT_SPINNER = 'APP_SPINNER';
export const DEFAULT_SPINNER_SIZE: Size = 'medium';
export const DEFAULT_SPINNER_TEXT = 'Aguarde...';

export const DEFAULT_DECORATOR_OPTIONS: SpinnerOptions = Object.freeze({
  name: DEFAULT_SPINNER,
  text: DEFAULT_SPINNER_TEXT,
});

export const SHOW_SPINNER_METADATA = Symbol('__SHOW_SPINNER__');
