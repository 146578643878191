import { Platform } from '@angular/cdk/platform';
import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

import { IHttpService } from './i-http-service/ihttp.service';

@Injectable()
export class HttpService {
  static $instance: HttpService;
  private readonly _httpOptions: any;
  private _oauthTokenPrefix = 'Bearer';

  constructor(private http: HttpClient) {
    this.baseApi = environment.apiUrl;
    this._httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    };

    HttpService.$instance = this;
  }

  private _baseApi: string;

  public get baseApi(): string {
    return this._baseApi;
  }

  public set baseApi(value: string) {
    this._baseApi = value;
  }

  as<T>(entity: new () => T): IHttpService<T> {
    return new IHttpService<T>(this, this.http, entity);
  }

  get<T>(url: any, data?, baseUrl?: string): Observable<HttpEvent<T>> {
    const oauthToken: string = this.getOAuthToken();

    if (oauthToken) {
      this._httpOptions.headers = this._httpOptions.headers.set(
        'Authorization',
        oauthToken
      );
    }

    this._httpOptions.params = {};

    if (data) {
      this._httpOptions.params = data;
    }

    if (baseUrl) {
      return this.http.get<T>(`${baseUrl}${url}`, this._httpOptions);
    }

    return this.http.get<T>(`${this._baseApi}${url}`, this._httpOptions);
  }

  post<T = any>(
    url,
    data,
    options = null,
    baseUrl?: string
  ): Observable<HttpEvent<T>> {
    const oauthToken: string = this.getOAuthToken();

    if (oauthToken) {
      this._httpOptions.headers = this._httpOptions.headers.set(
        'Authorization',
        oauthToken
      );
    }

    if (!options) {
      options = this._httpOptions;
    }

    this._httpOptions.params = {};

    if (baseUrl) {
      return this.http.post<any>(`${baseUrl}${url}`, data, options);
    }

    return this.http.post<any>(`${this._baseApi}${url}`, data, options);
  }

  put<T = any>(
    url,
    data,
    options = null,
    baseUrl?: string
  ): Observable<HttpEvent<T>> {
    const oauthToken: string = this.getOAuthToken();

    if (!options) {
      if (oauthToken) {
        this._httpOptions.headers.set('Authorization', oauthToken);
      }

      options = this._httpOptions;
    }

    this._httpOptions.params = {};

    if (baseUrl) {
      return this.http.put<any>(`${baseUrl}${url}`, data, options);
    }
    return this.http.put<any>(`${this._baseApi}${url}`, data, options);
  }

  delete<T>(
    url: string,
    options = null,
    baseUrl?: string
  ): Observable<HttpEvent<T>> {
    const oauthToken: string = this.getOAuthToken();

    if (!options && oauthToken) {
      this._httpOptions.headers = this._httpOptions.headers.set(
        'Authorization',
        oauthToken
      );
      options = this._httpOptions;
    }

    this._httpOptions.params = {};

    if (baseUrl) {
      return this.http.delete<T>(`${baseUrl}${url}`, options);
    }
    return this.http.delete<T>(`${this._baseApi}${url}`, options);
  }

  getOAuthToken(): string {
    const token = localStorage.getItem('accessToken');

    if (!token) {
      return null;
    }

    return `${this._oauthTokenPrefix} ${token}`;
  }
}
