import { Component, OnDestroy, OnInit } from '@angular/core';
import { DEFAULT_SPINNER, DEFAULT_SPINNER_SIZE } from '@imagine/i-spinner';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { ApplicationState } from './store/application';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public readonly DEFAULT_SPINNER = DEFAULT_SPINNER;
  public readonly DEFAULT_SPINNER_SIZE = DEFAULT_SPINNER_SIZE;

  @ViewSelectSnapshot(ApplicationState.getSpinnerText)
  public readonly spinnerText: string;
}
