import { EntityService } from './entity-service';
import { ApiMetadata } from './interfaces';

export class DynamicHttpServiceMetadata {
  private static instances = new Map<string, EntityService>();
  private static timeUsages = new Map<string, number>();

  static add<TEntity>(meta: ApiMetadata<TEntity>): EntityService<TEntity> {
    if (DynamicHttpServiceMetadata.has(meta)) {
      return DynamicHttpServiceMetadata.get(meta);
    }

    const key = DynamicHttpServiceMetadata.getKey(meta);

    const instance = new EntityService<TEntity>(meta.target);

    this.timeUsages.set(key, Date.now());

    DynamicHttpServiceMetadata.instances.set(key, instance);

    return instance;
  }

  static get<TEntity>(meta: ApiMetadata<TEntity>): EntityService<TEntity> {
    const key = DynamicHttpServiceMetadata.getKey(meta);

    const instance = DynamicHttpServiceMetadata.instances.get(key);

    return instance || DynamicHttpServiceMetadata.add(meta);
  }

  static has(meta: ApiMetadata): boolean {
    const key = DynamicHttpServiceMetadata.getKey(meta);

    return DynamicHttpServiceMetadata.instances.has(key);
  }

  private static getKey({ target, propertyKey, objConstructorName }: ApiMetadata): string {
    return `__${objConstructorName}_${target.name}_${propertyKey}__`;
  }
}
