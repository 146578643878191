import { plainToClass } from 'class-transformer';
import { ClassTransformOptions } from 'class-transformer/types/interfaces';

import { Type } from '../interfaces';

const defaultOptions: ClassTransformOptions = {
  exposeDefaultValues: true,
  enableCircularCheck: true
};

export const createClass = <T = any>(target: Type<T>, data: Partial<T>, options?: ClassTransformOptions): T => plainToClass(
  target,
  data,
  options ? Object.assign({}, defaultOptions, options) : defaultOptions
);
