import { Injectable } from '@angular/core';
import { EmitterAction, Receiver } from '@ngxs-labs/emitter';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { UpdateNetworkConnected } from './update-network-connected.state';

export interface SyncModel {
  networkConnected?: boolean;
}

@State<SyncModel>({
  name: 'sync',
  defaults: {
    networkConnected: false,
  },
})
@Injectable()
export class SyncState {
  @Receiver()
  public static setNetworkConnected(
    { patchState }: StateContext<SyncModel>,
    { payload }: EmitterAction<boolean>
  ): void {
    patchState({ networkConnected: payload });
  }

  @Action(UpdateNetworkConnected)
  updateNetworkConnected(
    { patchState }: StateContext<SyncModel>,
    { networkConnected }: UpdateNetworkConnected
  ) {
    patchState({ networkConnected });
  }

  @Selector()
  public static getNetworkConnected(state: SyncModel): boolean {
    return state.networkConnected;
  }
}
