import { Injectable } from '@angular/core';
import { Emittable, Emitter } from '@ngxs-labs/emitter';
import { NgxSpinnerService } from 'ngx-spinner';

import { ApplicationState } from '../../app/store/application';

import { DEFAULT_SPINNER, DEFAULT_SPINNER_TEXT } from './constants';

@Injectable({
  providedIn: 'root',
})
export class ISpinnerService {
  @Emitter(ApplicationState.setSpinnerText)
  private readonly updateText: Emittable<string>;

  private shouldResetSpinnerText = false;

  constructor(private _ngxSpinnerService: NgxSpinnerService) {}

  show(name: string = DEFAULT_SPINNER): Promise<any> {
    return this._ngxSpinnerService.show(name);
  }

  hide(name: string = DEFAULT_SPINNER): Promise<any> {
    return this._ngxSpinnerService
      .hide(name, 400)
      .finally(() => this.resetText());
  }

  setText(text?: string): this {
    text = text ?? DEFAULT_SPINNER_TEXT;

    if (text !== DEFAULT_SPINNER_TEXT) {
      this.updateText.emit(text);
      this.shouldResetSpinnerText = true;
    }

    return this;
  }

  private resetText(): void {
    if (this.shouldResetSpinnerText) {
      this.updateText.emit(DEFAULT_SPINNER_TEXT);
      this.shouldResetSpinnerText = false;
    }
  }
}
