import { NgModule } from '@angular/core';
import { AppDB } from './db.service';
import { SyncListenerComponent } from './listener/sync-listener.component';
import { SyncStatusColorPipe } from './pipes/sync-status-color.pipe';
import { SyncStatusPipe } from './pipes/sync-status.pipe';
import { SyncService } from './sync.service';

@NgModule({
  declarations: [SyncListenerComponent, SyncStatusPipe, SyncStatusColorPipe],
  providers: [AppDB, SyncService],
  exports: [SyncListenerComponent, SyncStatusPipe, SyncStatusColorPipe],
})
export class SyncModule {}
