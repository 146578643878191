import { NgModule } from '@angular/core';
import { ApplicationPenaltyType } from 'app/entities/application-penalty-type.entity';
import { Application } from 'app/entities/applications.entity';
import { CompanySize } from 'app/entities/company-size.entity';
import { DecreeSection } from 'app/entities/decree-section.entity';
import { Laws } from 'app/entities/laws.entity';
import { PeopleCity } from 'app/entities/people-city.entity';
import { PeopleFederativeUnit } from 'app/entities/people-federative-unit.entity';
import { People } from 'app/entities/people.entity';
import { UserAdmin } from 'app/entities/user-admin.entity';

@NgModule({
  providers: [
    Application,
    People,
    UserAdmin,
    Laws,
    DecreeSection,
    PeopleFederativeUnit,
    PeopleCity,
    ApplicationPenaltyType,
    CompanySize,
  ],
})
export class EntityLoaderModule {}
