import { Name } from 'app/decorators/name.decorator';
import { Sync } from 'app/decorators/sync.decorator';
import { Endpoint } from 'app/services/i-http-service';

@Sync('get')
@Endpoint('penalty/types')
@Name('Tipo da multa')
export class ApplicationPenaltyType {
  id: number;
  name: string;
}
