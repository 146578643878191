import { merge } from 'lodash-es';

import { DEFAULT_DECORATOR_OPTIONS, SHOW_SPINNER_METADATA } from '../constants';
import { SpinnerOptions } from '../interfaces';

export const getSpinnerMetadata = (target: Object, propertyKey: string | symbol): SpinnerOptions | false => {
  const metadata = Reflect.getMetadata(SHOW_SPINNER_METADATA, target.constructor, propertyKey) as SpinnerOptions | undefined;

  if (metadata) {
    return merge({}, DEFAULT_DECORATOR_OPTIONS, metadata) as SpinnerOptions;
  }

  return false;
};
