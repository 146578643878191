import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';

import { BreadcrumbModule } from '../breadcrumb/breadcrumb.module';

import { DefaultLayoutComponent } from './layouts/default/default.component';
import { SingleLayoutComponent } from './layouts/single/single.component';
import { PageLayoutComponent } from './page-layout.component';

@NgModule({
  declarations: [
    PageLayoutComponent,
    SingleLayoutComponent,
    DefaultLayoutComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    BreadcrumbModule,
  ],
  exports: [PageLayoutComponent],
})
export class PageLayoutModule {}
