<mat-nav-list>
	<h2
		mat-subheader
		*ngIf="data.message.color"
		class="{{ data.message.color }}-fg"
	>
		{{ data.message.text }}
	</h2>
	<h2 mat-subheader *ngIf="!data.message.color">{{ data.message.text }}</h2>

	<div *ngFor="let choice of data.choices" (click)="close(choice.value)">
		<div *ngIf="!choice.hide">
			<mat-divider *ngIf="choice.divider"></mat-divider>
			<a mat-list-item>
				<div mat-line *ngIf="choice?.value !== undefined">
					<mat-icon
						*ngIf="choice.icon && !choice.external"
						class="text-sub"
						>{{ choice.icon }}</mat-icon
					>
					<mat-icon
						*ngIf="choice.icon && choice.external"
						class="text-sub"
						svgIcon="{{ choice.icon }}"
					></mat-icon>
					<span *ngIf="choice.message"> {{ choice.message }}</span>
				</div>
			</a>
		</div>
	</div>
</mat-nav-list>
