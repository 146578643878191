import { Type } from '@imagine/common';
import syncMetadata, { FileSyncOptions } from '@sync/sync.metadata';

export const FileSync =
  (fileEntity: Type, options: FileSyncOptions = {}): PropertyDecorator =>
  (target: () => void, propertyKey: string): void => {
    syncMetadata.fileSync.push({
      target: target.constructor,
      propertyKey,
      options,
      fileEntity,
    });
  };
