import { SearchField } from '../interfaces';

import { parseCriteriaFilter } from './parse-criteria-filter';

export class CriteriaFilter {
  private readonly map = new Map<string, SearchField>();

  get data(): SearchField[] {
    return Array.from(this.map.values());
  }

  static build(fields: Record<string, string | number | boolean>): CriteriaFilter {
    const instance = new CriteriaFilter();

    Object.keys(fields).forEach((field) => {
      const value = fields[field];

      instance.set({ field, value });
    });

    return instance;
  }

  set(field: SearchField): this {
    this.map.set(field.field, field);
    return this;
  }

  remove(field: SearchField): this {
    this.map.delete(field.field);
    return this;
  }

  get(name: string): SearchField | undefined {
    return this.map.get(name);
  }

  reset(): this {
    this.map.clear();

    return this;
  }

  toString(): string {
    return parseCriteriaFilter(this);
  }
}
