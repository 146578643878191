import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { SheetMultipleChoiceComponent } from '../../app/layout/common/sheet-multiple-choice/sheet-multiple-choice.component';

interface SheetConfirmarAcaoInterface {
  titulo?: string;
  texto: {
    sim?: string;
    nao?: string;
  };
}

@Injectable()
export class SheetService<T> {
  constructor(public bottomSheet: MatBottomSheet, public dialog: MatDialog) {}

  async open(component: any, data: any, forceDiaglog = false): Promise<T> {
    if (forceDiaglog) {
      return this.dialog.open(component, data).afterClosed().toPromise();
    } else {
      return this.bottomSheet
        .open(component, data)
        .afterDismissed()
        .toPromise();
    }
  }

  async confirmarAcao(params?: SheetConfirmarAcaoInterface): Promise<boolean> {
    const resposta = await this.open(SheetMultipleChoiceComponent, {
      data: {
        message: {
          text: params?.titulo || 'Deseja prosseguir?',
        },
        choices: [
          {
            message: params?.texto?.sim || 'Sim',
            value: true,
          },
          {
            message: params?.texto?.nao || 'Não',
            value: false,
          },
        ],
      },
    });

    if (!resposta) {
      return false;
    }

    return !!resposta;
  }
}
