import { AppModule } from '../../../app/app.module';
import { PromiseHelper } from '../../common/promises';
import { DEFAULT_DECORATOR_OPTIONS } from '../constants';
import { ISpinnerService } from '../i-spinner.service';
import { SpinnerOptions } from '../interfaces';

// Mostra o spinner quando um metodo for executado
export const ShowSpinner = (options: SpinnerOptions = {}): MethodDecorator => (
  target: Object,
  propertyKey: string,
  descriptor: PropertyDescriptor
): PropertyDescriptor => {
  const { name, fadeOut, handleMultiple, text }: SpinnerOptions = Object.assign({}, DEFAULT_DECORATOR_OPTIONS, options);

  const originalMethod: (...args: any[]) => any = descriptor.value;
  const show = (service: ISpinnerService): void => {
    const exec = (): void => PromiseHelper.executeSync(() => service.setText(text).show(name));

    if (handleMultiple) {
      setTimeout(exec, 20);
    } else {
      exec();
    }
  };

  const hide = (service: ISpinnerService): void => {
    if (fadeOut) {
      setTimeout(() => service.hide(name), fadeOut);
    } else {
      PromiseHelper.executeSync(() => service.hide(name));
    }
  };

  descriptor.value = function(...args: any[]): any {
    const service = AppModule?.injector().get(ISpinnerService);

    try {
      show(service);

      return originalMethod.apply(this, args);
    } finally {
      hide(service);
    }
  };

  return descriptor;
};
