import { CRITERIA_SEARCHABLE } from '../constants';
import { FieldMetadataOptions } from '../interfaces';

export const Searchable =
  (options?: FieldMetadataOptions): PropertyDecorator =>
  (target, propertyKey: string): void => {
    const oldMetadata: FieldMetadataOptions[] =
      Reflect.getMetadata(CRITERIA_SEARCHABLE, target.constructor) || [];

    oldMetadata.push({ name: options?.name || propertyKey });

    Reflect.defineMetadata(
      CRITERIA_SEARCHABLE,
      oldMetadata,
      target.constructor
    );
  };
