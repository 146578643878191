import { EventEmitter } from '@angular/core';
import { merge } from 'lodash-es';
import { BehaviorSubject, Observable } from 'rxjs';

import { EntityService } from '../../dynamic-http-service';
import { Type } from '../interfaces';
import { createRandomToken } from '../utils';

import { CriteriaEntityServiceLink } from './criteria-entity-service-link';
import { CriteriaFilter } from './criteria-filter';
import { parseCriteriaFilter } from './criteria-filter/parse-criteria-filter';
import { getFieldsSearchable } from './get-criteria';
import { CriteriaOptions, CriteriaSearchOptions } from './interfaces';

export type CriteriaProps = Omit<CriteriaOptions, 'filter'>;

const DEFAULT_CRITERIA_PROPS: CriteriaProps = Object.freeze({
  getAll: false,
  join: false
});

export class Criteria<Entity = any> {
  public readonly ref = createRandomToken();

  public readonly filter = new CriteriaFilter();

  public readonly optionsChange: EventEmitter<CriteriaProps> = new EventEmitter<CriteriaProps>();

  private _entity?: Type<Entity>;

  private _search = new BehaviorSubject<string>('');

  private _options: CriteriaProps;

  private _convertResponse = true;

  private entityServiceLink?: CriteriaEntityServiceLink;

  constructor(entity?: Type<Entity>, options: CriteriaProps = {}) {
    this._entity = entity;

    this._options = merge({}, DEFAULT_CRITERIA_PROPS, options);
  }

  linkEntityService(entityService: EntityService<Entity>): CriteriaEntityServiceLink {
    this.entityServiceLink = new CriteriaEntityServiceLink(this, entityService);

    return this.entityServiceLink;
  }

  get search$(): Observable<string> {
    return this._search;
  }

  convertClass(state: boolean): void {
    this._convertResponse = state;
  }

  options(data: Partial<CriteriaProps>): this {
    Object.assign(this._options, data);

    this.optionsChange.emit(this._options);

    return this;
  }

  setOptions(data: CriteriaProps): this {
    this._options = data;

    this.optionsChange.emit(this._options);

    return this;
  }

  entity(entity: Type<Entity>): this {
    this._entity = entity;

    return this;
  }

  search(value?: string, options?: CriteriaSearchOptions<Entity>): CriteriaOptions {
    if (!this._entity) {
      throw new Error('Uma entidade deve ser definida para pesquisar');
    }

    const searchableFields = getFieldsSearchable(this._entity);

    // ! Resetar o filtro implica em remover filtros que poderiam ter sido adicionados anteriormente na página.
    // ! Por exemplo, em categorias, o filtro da tab atual é sempre fixo, e não pode ser removido.
    //this.filter.reset();

    const not: (string | keyof Entity)[] = options?.not ?? [];

    searchableFields
      .filter(({ name }) => !not.includes(name)) // Apenas campos que não estão nos "não pesquisaveis"
      .forEach((field) => {
        this.filter.set({ field: field.name, value });
      });

    this._search.next(value);

    return this.toObject();
  }

  get skipClassConversion(): boolean {
    return !this._convertResponse;
  }

  toObject(): CriteriaOptions {
    const options: CriteriaOptions = { ...this._options };

    if (this.filter.data.length > 0) {
      options.filter = parseCriteriaFilter(this.filter);
    }

    return options;
  }

  toString(): string {
    const criteria = [];

    if (this.filter.data.length > 0) {
      const filter = parseCriteriaFilter(this.filter);

      criteria.push(`filter=${filter}`);
    }

    Object.keys(this._options).forEach((key) => {
      const value = this._options[key];

      criteria.push(`${key}=${value}`);
    });

    return criteria.join('&');
  }

  getEntity(): Type<Entity> {
    return this._entity;
  }
}
