import SparkMD5 from 'spark-md5';

const CHUCK_SIZE_BYTES = 2_000_000; // 2 MiB

export const createContentIntegrity = (file: Blob | File): Promise<string> =>
  new Promise((resolve, reject) => {
    let currentChunk: number = 0;

    const fileReader = new FileReader();
    const hash = new SparkMD5.ArrayBuffer();

    const chucks = Math.ceil(file.size / CHUCK_SIZE_BYTES);

    // Parse chunk
    fileReader.onload = (event): void => {
      hash.append(event.target.result as ArrayBuffer);
      currentChunk++;

      if (currentChunk < chucks) {
        loadChunk();
      } else {
        resolve(hash.end());
      }
    };

    fileReader.onerror = (event): void => {
      reject(event.target.error);
    };

    const loadChunk = (): void => {
      const start: number = currentChunk * CHUCK_SIZE_BYTES;
      let end: number = start + CHUCK_SIZE_BYTES;

      // If chunk-end-size is greater than file size, use file size as chunk-end-size
      if (end > file.size) {
        end = file.size;
      }

      // Read the chunk slice, it will call onload
      fileReader.readAsArrayBuffer(file.slice(start, end));
    };

    // Start loading chunks
    loadChunk();
  });
