import { NgModule } from '@angular/core';
import { NgxsEmitPluginModule } from '@ngxs-labs/emitter';
import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';

import { environment } from '../../environments/environment';

import { ApplicationState } from './application';
import { BluetoothState } from './bluetooth';
import { InfractionState } from './infraction/infraction.state';
import { SessionState } from './session';
import { SyncState } from './sync';

@NgModule({
  imports: [
    NgxsStoragePluginModule.forRoot({
      key: [SessionState, BluetoothState],
    }),
    NgxsModule.forRoot(
      [
        SessionState,
        ApplicationState,
        BluetoothState,
        SyncState,
        InfractionState,
      ],
      {
        developmentMode: !environment.production,
      }
    ),
    NgxsFormPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot({
      disabled: environment.production,
    }),
    NgxsSelectSnapshotModule.forRoot(),
    NgxsEmitPluginModule.forRoot(),
  ],
})
export class StoreModule {}
