export enum Variant {
  CIRCLE,
  ROUNDED,
}

export enum Size {
  SMALL,
  MEDIUM,
  LARGE,
  FULL,
}

export const AvatarSizeMap: Record<Size, string> = {
  [Size.SMALL]: 'w-10 h-10',
  [Size.MEDIUM]: 'w-12 h-12',
  [Size.LARGE]: 'w-16 h-16',
  [Size.FULL]: 'w-full h-full',
};

export const AvatarFontSizeMap: Record<Size, string> = {
  [Size.SMALL]: 'text-md',
  [Size.MEDIUM]: 'text-lg',
  [Size.LARGE]: 'text-xl',
  [Size.FULL]: 'text-xl',
};
