import { noop } from 'lodash';

import { AppModule } from '../../../app/app.module';
import { ISpinnerService } from '../i-spinner.service';
import { SpinnerFactory } from '../interfaces';

import { getSpinnerMetadata } from './get-spinner-metadata';

export const spinnerMetadataFactory = (target: Object, propertyKey: string | symbol): SpinnerFactory => {
  const spinner = getSpinnerMetadata(target, propertyKey);

  if (!spinner) {
    return noop;
  }

  const service = AppModule.injector().get(ISpinnerService);

  let showing = false;

  return async (): Promise<void> => {
    if (!showing) {
      showing = true;
      await service
        .setText(spinner.text)
        .show(spinner.name);
    } else {
      if (spinner.fadeOut) {
        setTimeout(() => service.hide(spinner.name), spinner.fadeOut);
        return;
      }

      await service.hide(spinner.name);
    }
  };
};
