import { Component, OnInit } from '@angular/core';
import { Network } from '@capacitor/network';
import { ImagineSnack } from '@imagine/services/imagine-snack.service';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { SyncState } from 'app/store/sync';
import debug from 'debug';
import { SyncService } from '../sync.service';

@Component({
  selector: 'sync-listener',
  templateUrl: 'sync-listener.component.html',
})
export class SyncListenerComponent implements OnInit {
  private log = debug(SyncListenerComponent.name);

  @SelectSnapshot(SyncState.getNetworkConnected)
  isNetworkConnected: boolean;

  /**
   * Constructor
   */
  constructor(
    private imagineSnack: ImagineSnack,
    private syncService: SyncService
  ) {}

  ngOnInit(): void {
    this.setupNetworkListeners();
  }

  ngOnDestroy(): void {
    Network.removeAllListeners();
  }

  async setupNetworkListeners(): Promise<void> {
    try {
      const { connected } = await Network.getStatus();

      this.log('initialNetworkStatus', connected);

      this.syncService.setNetworkConnected(connected);
    } catch (error) {
      this.imagineSnack.open(
        'Não foi possível obter o status da conexão com a internet',
        { type: 'error' }
      );
    }

    Network.addListener('networkStatusChange', ({ connected }) => {
      if (connected !== this.isNetworkConnected) {
        this.log('networkStatusChange', connected);

        this.syncService.setNetworkConnected(connected);
      }
    });
  }
}
