import { Injectable } from "@angular/core";
import { EmitterAction, Receiver } from "@ngxs-labs/emitter";
import { Action, Selector, State, StateContext } from "@ngxs/store";

export interface ApplicationModel {
  spinnerText?: string;
  isViewingIndicator?: boolean;
  isViewingFormItem?: boolean;
}

@State<ApplicationModel>({
  name: "application",
  defaults: {
    spinnerText: "Aguarde...",
    isViewingIndicator: false,
    isViewingFormItem: false,
  },
})
@Injectable()
export class ApplicationState {
  @Receiver()
  public static setSpinnerText(
    { patchState }: StateContext<ApplicationModel>,
    { payload }: EmitterAction<string>,
  ): void {
    patchState({ spinnerText: payload });
  }

  @Selector()
  public static getSpinnerText(state: ApplicationModel): string {
    return state.spinnerText;
  }
}
