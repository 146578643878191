/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { ApplicationPenaltyType } from 'app/entities/application-penalty-type.entity';
import { Application } from 'app/entities/applications.entity';
import { CompanySize } from 'app/entities/company-size.entity';
import { DecreeSection } from 'app/entities/decree-section.entity';
import { Laws } from 'app/entities/laws.entity';
import { PeopleCity } from 'app/entities/people-city.entity';
import { PeopleFederativeUnit } from 'app/entities/people-federative-unit.entity';
import { People } from 'app/entities/people.entity';
import { UserAdmin } from 'app/entities/user-admin.entity';
import debug from 'debug';
import Dexie, { Table } from 'dexie';
import { DB_VERSION } from './constants';
import syncMetadata from './sync.metadata';
import { SyncStatus } from './types';

export interface Syncable<T> {
  payload: T;
  response?: any;
  id?: number;
  createdAt: Date;
  method: 'POST' | 'PUT' | 'DELETE';
  status: SyncStatus;
  uuid: string;
  dbVersion?: number;
  lastSync?: Date;
  lastErrorCode?: any;
  lastErrorAt?: any;
  forcedSync?: boolean;
  appVersion?: string;
  lastTriedVersion?: string;
  lastSyncForced?: boolean;
  lastErrorMessage?: any;
  syncTries?: number;
  userId: number;
}

@Injectable()
export class AppDB extends Dexie {
  Application!: Table<Syncable<Application>, number>;
  DecreeSection!: Table<DecreeSection, number>;
  People!: Table<People, number>;
  UserAdmin!: Table<UserAdmin, number>;
  Laws!: Table<Laws, number>;
  PeopleCity!: Table<PeopleCity, number>;
  PeopleFederativeUnit!: Table<PeopleFederativeUnit, number>;
  CompanySize!: Table<CompanySize, number>;
  ApplicationPenaltyType!: Table<ApplicationPenaltyType, number>;

  log = debug(AppDB.name);

  constructor() {
    super(AppDB.name);

    const schema = this.getSchema();

    this.log(schema);

    this.version(DB_VERSION).stores(schema);
  }

  getSchema() {
    return syncMetadata.entites.reduce((previousValue, currentValue) => {
      const indexedKeys = ['++id'];

      if (currentValue.mode === 'queue') {
        indexedKeys.push('status', 'userId');
      }

      if (currentValue.indexedKeys) {
        indexedKeys.push(...currentValue.indexedKeys);
      }

      this.log(indexedKeys.join(','));

      return {
        ...previousValue,
        [currentValue.target.name]: indexedKeys.join(','),
      };
    }, {});
  }
}
