import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';

import { ImagineAvatarComponent } from './i-avatar.component';

@NgModule({
  declarations: [ImagineAvatarComponent],
  imports: [SharedModule],
  exports: [ImagineAvatarComponent],
})
export class ImagineAvatarModule {}
