import { Component, Inject } from '@angular/core';
import {
	MAT_BOTTOM_SHEET_DATA,
	MatBottomSheetRef,
} from '@angular/material/bottom-sheet';

@Component({
	selector: 'sheet-multiple-choice',
	templateUrl: 'sheet-multiple-choice.component.html',
	styles: [
		`
			.mat-line {
				color: rgba(0, 0, 0, 0.54);
			}
		`,
	],
})
export class SheetMultipleChoiceComponent {
	constructor(
		public sheetRef: MatBottomSheetRef<SheetMultipleChoiceComponent>,
		@Inject(MAT_BOTTOM_SHEET_DATA) public data: any
	) {}

	close(res): any {
		this.sheetRef.dismiss(res);
	}
}
