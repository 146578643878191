import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { SnackComponentOptions } from '@imagine/services/imagine-snack.service';

type Data = SnackComponentOptions & {
  message: string;
};

@Component({
  selector: 'i-snackbar',
  templateUrl: './i-snackbar.component.html',
})
export class ISnackbarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: Data) {}
}
