import { PortalModule } from '@angular/cdk/portal';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';

import { FooterActionsComponent } from './footer-actions.component';

@NgModule({
  declarations: [FooterActionsComponent],
  imports: [
    FuseSharedModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    PortalModule,
  ],
  exports: [FooterActionsComponent],
})
export class FooterActionsModule {}
