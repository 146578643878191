import { Injectable } from '@angular/core';
import { EmitterAction, Receiver } from '@ngxs-labs/emitter';
import { Selector, State, StateContext } from '@ngxs/store';

import { createClass } from '../../../@imagine/common';
import { UserAdmin } from '../../entities/user-admin.entity';

export interface SessionStateModel {
  user: UserAdmin | null;
}

@State<SessionStateModel>({
  name: 'sessionState',
  defaults: {
    user: null,
  },
})
@Injectable()
export class SessionState {
  @Selector()
  public static getUser(state: SessionStateModel): UserAdmin | null {
    return createClass(UserAdmin, state.user, {
      enableCircularCheck: false,
    });
  }

  @Receiver()
  public static setUser(
    { patchState }: StateContext<SessionStateModel>,
    { payload }: EmitterAction<UserAdmin>
  ): void {
    patchState({ user: payload });
  }
}
